import React, {useMemo} from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import Layout from 'components/layout/Layout';
import ModelTile from 'components/ModelTile';
import Container from 'components/containers/Container';
import Grid from 'components/containers/Grid';
import SEO from "../components/Seo";

const StyledGrid = styled(Grid)`
  max-width: 100%;
`;

const StyledGallery = styled.div`
  padding-top: 40px;
`

const StyledTitle = styled.h2`
  text-transform: uppercase;
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
`

const Models = ({data, location}) => {
  const models = useMemo(() => {
    const newFaces = [];
    const developmentModels = [];
    const verifiedModels = [];
    data.allDatoCmsModelEntity.edges
      .sort((a, b) => ( a.node.position - b.node.position ))
      .forEach(({node}) => {
        const {
          name, slug, height, waist, bust, hips, mainImage, section, international, gender,
        } = node;
        const model = {
          name,
          slug,
          imageUrl: mainImage.url,
          fluid: mainImage.imageFile.childImageSharp.fluid,
          international,
          gender,
          sizes: {
            height, waist, bust, hips,
          },
        };
        if (section === 'new face') {
          newFaces.push(model)
        }
        if (section === 'development') {
          developmentModels.push(model)
        }
        if (section === 'main'){
          verifiedModels.push(model)
        }
      })
    return {verifiedModels, newFaces, developmentModels}
  }, []);
  return (
    <Layout location={location}>
      <SEO title="MODELS"/>
      <Container size="l">
        {models.verifiedModels.length ?
          <StyledGallery>
            <StyledTitle>#onmovemodels</StyledTitle>
            <StyledGrid
              customL={1180}
              cols="repeat(5, 1fr)"
              colsL="repeat(4, 1fr)"
              colsM="repeat(3, 1fr)"
              colsS="repeat(2, 1fr)"
              gap="35px"
              padding="40px 10px 0 10px"
            >
              {models.verifiedModels.map(model => (
                <ModelTile
                  key={model.slug}
                  {...model}
                />
              ))}
            </StyledGrid>
          </StyledGallery>
          : null
        }
        {models.developmentModels.length ?
          <StyledGallery>
            <StyledTitle>Development</StyledTitle>
            <StyledGrid
              customL={1180}
              cols="repeat(5, 1fr)"
              colsL="repeat(4, 1fr)"
              colsM="repeat(3, 1fr)"
              colsS="repeat(2, 1fr)"
              gap="35px"
              padding="40px 10px 0 10px"
            >
              {models.developmentModels.map(model => (
                <ModelTile
                  key={model.slug}
                  {...model}
                />
              ))}
            </StyledGrid>
          </StyledGallery>
          : null
        }
        {models.newFaces.length ?
          <StyledGallery>
            <StyledTitle>New face</StyledTitle>
            <StyledGrid
              customL={1180}
              cols="repeat(5, 1fr)"
              colsL="repeat(4, 1fr)"
              colsM="repeat(3, 1fr)"
              colsS="repeat(2, 1fr)"
              gap="35px"
              padding="40px 10px 0 10px"
            >
              {models.newFaces.map(model => (
                <ModelTile
                  key={model.slug}
                  {...model}
                />
              ))}
            </StyledGrid>
          </StyledGallery>
          : null
        }
      </Container>
    </Layout>
  );
};

export default Models;

export const query = graphql`
    query AllModels {
        allDatoCmsModelEntity {
            edges {
                node {
                    id
                    position
                    slug
                    height
                    waist
                    bust
                    name
                    hips
                    section
                    international
                    gender
                    mainImage {
                        imageFile {
                            childImageSharp {
                                fluid {
                                    src
                                    base64
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    presentationHeight
                                    aspectRatio
                                    sizes
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                        url
                    }
                }
            }
        }
    }
`;
